import React from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    ${(p) => p.alignLeft && "justify-content: flex-start;"}
  }
`;

const Button = styled(Link)`
  padding: 7px 32px;
  border-radius: 5px;
  background-color: ${(p) => p.theme.red};
  font-size: 16px;
  line-height: 20px;
  font-weight: 900;
  color: white;
  text-align: center;
  ${(p) =>
    p.small &&
    css`
      font-size: 12px;
      line-height: 14px;
    `}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding: 11px 68px;
    font-size: 16px;
    line-height: 20px;
  }
  ${(p) =>
    p.inverted &&
    css`
      background-color: ${(p) => p.theme.lightGrey};
      color: ${(p) => p.theme.darkGrey};
    `}
`;

const PrimaryButton = ({
  text,
  link,
  externalLink,
  inverted,
  alignLeft,
  isButton,
  small,
  target,
}) => {
  return (
    <Wrapper alignLeft={alignLeft}>
      {isButton ? (
        <Button as="button" inverted={inverted} small={small.toString()}>
          {text}
        </Button>
      ) : link ? (
        <Button to={link} inverted={inverted} small={small.toString()}>
          {text}
        </Button>
      ) : (
        externalLink && (
          <Button
            as="a"
            href={externalLink}
            target={target ? target : "_blank"}
            rel="noopener noreferrer"
            inverted={inverted}
            small={small}
          >
            {text}
          </Button>
        )
      )}
    </Wrapper>
  );
};

PrimaryButton.defaultProps = {
  small: false
}

export default PrimaryButton;
