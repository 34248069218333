import React from "react";
import styled from "styled-components";

import Text from "./Text";
import PrimaryButton from "./PrimaryButton";

import Container from "../styles/Container";

const Wrapper = styled.div`
  padding-top: 23px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
  }
`;

const Image = styled.img`
  width: 100%;
  min-height: 205px;
  margin-bottom: 27px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    height: 450px;
    margin-bottom: 38px;
  }
  @media (min-width: 1400px) {
    height: 600px;
  }
`;

const Heading = styled.h1`
  font-size: 24px;
  line-height: 30px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 24px;
  text-transform: uppercase;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 32px;
    line-height: 40px;
  }
`;

const ButtonWrapper = styled.div`
  margin-bottom: 37px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  > div:not(:last-child) {
    margin-bottom: 16px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    > div:not(:last-child) {
      margin-right: 16px;
      margin-bottom: 0px;
    }
    flex-direction: row;
    margin-bottom: 50px;
  }
`;

const MovieHero = ({ image, heading, text, text2, buttons }) => {
  return (
    <Wrapper>
      {image && (
        <div>
          <Image src={image.url} alt={image.alt} />
        </div>
      )}
      {heading && (
        <Container>
          <Heading>{heading}</Heading>
        </Container>
      )}
      {text && <Text text={text} boldLinks />}
      <Container>
        {buttons && buttons[0].button_text && (
          <ButtonWrapper>
            {buttons.map((button, index) => {
              if (button.button_text && button.button_link.url) {
                return (
                  <PrimaryButton
                    externalLink={button.button_link.url}
                    text={button.button_text}
                    key={index}
                  />
                );
              }
            })}
          </ButtonWrapper>
        )}
      </Container>
      {text2 && <Text text={text2} boldLinks />}
    </Wrapper>
  );
};

export default MovieHero;
