import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import get from "lodash.get";

import MovieHero from "../../../components/MovieHero";
import ImageList from "../../../components/ImageList";
import ViewNext from "../../../components/ViewNext";
import HelmetInfo from "../../../components/HelmetInfo";

const ImageContainer = styled.div`
  position: relative;
  padding-top: 23px;
  max-width: 1164px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 33px;
  }
`;

const Image = styled.img`
  width: 100%;
  min-height: 205px;
  object-fit: cover;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    height: 450px;
  }
  @media (min-width: 1400px) {
    height: 600px;
  }
`;

const USSyndication = ({ data }) => {
  const fluid = get(
    data,
    "prismic.us_syndication.hero_imageSharp.childImageSharp.fluid"
  );
  const previewImage = get(data, "prismic.us_syndication.hero_image");
  const heading = get(data, "prismic.us_syndication.heading");
  const description = get(data, "prismic.us_syndication.description");
  const contactDescription = get(
    data,
    "prismic.us_syndication.contact_description"
  );
  const buttons = get(data, "prismic.us_syndication.buttons");
  const body = get(data, "prismic.us_syndication.body");

  return (
    <>
      <HelmetInfo page_data={data.prismicUsSyndication.data} />
      <ImageContainer>
        <Image
          src={data.prismicUsSyndication.data.hero_image.url}
          alt={data.prismicUsSyndication.data.hero_image.alt}
        />
      </ImageContainer>
      <MovieHero
        heading={data.prismicUsSyndication.data.heading}
        text={data.prismicUsSyndication.data.description}
        text2={data.prismicUsSyndication.data.contact_description}
        buttons={data.prismicUsSyndication.data.buttons}
      />
      {data.prismicUsSyndication.data.body &&
        data.prismicUsSyndication.data.body.map((slice, index) => {
          if (slice.__typename === "PrismicUsSyndicationDataBodyImagelist") {
            return (
              <ImageList
                key={index}
                heading={slice.primary.heading}
                largeTitles
                items={slice.items}
              />
            );
          }
          return null;
        })}
      <ViewNext
        text="View Next Page"
        link={`/productions/original-programming`}
        linkText="Original Programming"
        borderTop
        whiteBackground
      />
    </>
  );
};

export default USSyndication;

export const query = graphql`
  query USSyndicationQuery {
    prismicUsSyndication {
      data {
        buttons {
          button_link {
            link_type
            url
            uid
          }
          button_text
        }
        contact_description {
          text
          html
          richText
        }
        description {
          text
          html
          richText
        }
        heading
        hero_image {
          alt
          copyright
          url
        }
        body {
          __typename
          ... on PrismicUsSyndicationDataBodyImagelist {
            primary {
              heading
            }
            items {
              image {
                url
                alt
              }
              title1
              info
              genre
              link {
                link_type
                url
                uid
              }
              youtube_video_id
            }
          }
        }
      }
    }
  }
`;
