import React from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";

const ViewNextContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  background-color: ${(p) => p.theme.lightBlue};
  color: ${(p) => p.theme.greyishBlue};
  ${(p) => p.borderTop && `border-top: 1px solid ${p.theme.lightGrey};`}
  ${(p) =>
    p.whiteBackground &&
    css`
      background-color: white;
      color: ${p.theme.textColor};
      font-size: 16px;
      line-height: 26px;
      @media (min-width: ${(p) => p.theme.breakpointMd}) {
        font-size: 20px;
      }
    `}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;
const ViewNextLinkLink = styled(Link)`
  display: inline-block;
  font-family: "effra", sans-serif;
  font-size: 31px;
  line-height: 41px;
  font-weight: 900;
  margin-top: 6px;
  padding-bottom: 10px;
  border-bottom: 3px solid ${(p) => p.theme.red};
  ${(p) =>
    p.whiteBackground &&
    css`
      margin-top: 12px;
      padding-bottom: 10px;
      font-size: 22px;
      color: ${(p) => p.theme.greyishBlue};
      @media (min-width: ${(p) => p.theme.breakpointMd}) {
        margin-top: 26px;
        padding-bottom: 26px;
        font-size: 45px;
      }
    `}
`;

const ViewNext = ({ text, link, linkText, borderTop, whiteBackground }) => {
  return (
    <div>
      <ViewNextContainer
        borderTop={borderTop}
        whiteBackground={whiteBackground}
      >
        {text ? <div>{text}</div> : <div>View Next</div>}
        <ViewNextLinkLink to={link} whiteBackground={whiteBackground}>
          {linkText}
        </ViewNextLinkLink>
      </ViewNextContainer>
    </div>
  );
};

export default ViewNext;
