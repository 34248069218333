import React from "react";
import styled, { css } from "styled-components";
import { RichText } from "prismic-reactjs";

import Container from "../styles/Container";

const TextContainer = styled.div`
  margin-bottom: 37px;
  text-align: center;
  ${(p) => p.leftAlign && "text-align: left;"}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 50px;
  }
  h1 {
    margin-bottom: 34px;
    font-size: 22px;
    line-height: 32px;
    font-weight: 900;
    text-align: center;
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      margin-bottom: 40px;
      font-size: 30px;
      line-height: 43px;
    }
  }
  p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 26px;
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 30px;
    }
  }
  a {
    text-decoration: underline;
    ${(p) =>
      p.boldLinks &&
      css`
        text-decoration: none;
        font-weight: 700;
        :hover {
          text-decoration: underline;
        }
      `}
  }
`;

const Text = ({ text, boldLinks, leftAlign }) => {
  return (
    <Container>
      {text && (
        <TextContainer leftAlign={leftAlign} boldLinks={boldLinks}>
          {RichText.render(text.richText)}
        </TextContainer>
      )}
    </Container>
  );
};

export default Text;
